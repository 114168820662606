import React, { useState,useRef,useEffect } from "react";
import "./Reviews.css";
import ReviewItem from "./ReviewItem";
import reviewOne from '../../assets/Reviews/one.mp4';
import reviewTwo from '../../assets/Reviews/two.mp4';
import reviewThree from '../../assets/Reviews/three.jpg';


function Reviews() {
  const [isHovered, setIsHovered] = useState(false);

  const handleOneOffButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/28o4jVaSIfpRgrSeUW';
  };
  const handleSubscribeButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/5kA17Je4UdhJ8Zq3cd';
  };
  

  return (
    
    <div className="review-page">
      <div className="container">
        <div className="reviewTitle">Listen to what our customers have to say!</div>
        <div className="reviewComponent row">
          <ReviewItem
          isEven={true}
            videoLink={reviewOne}
            name="mama_of_mischief_makers"
            message="CrownCrate is the perfect way to discover new premium hair products and treat yourself to a fun surprise every month!"
            image=""
          />
           <ReviewItem
           isEven={false}
           videoLink={reviewTwo}
            name="chrissie.mettrop"
            message="CrownCrate has been a fun addition to my monthly routine. I love opening the box to see what the theme is and exploring all the different products. It’s like getting a mini-spa day at home! The variety is pretty good, and it’s clear that they put thought into selecting quality products. I’ve been pleasantly surprised by how much I like some of the samples, and it’s been a great way to mix up my hair care regimen."
            image=""
          />
           <ReviewItem
           isEven={true}
            name="madison_brooke11"
            message="I subscribed to CrownCrate to try something new, and I’m glad I did! Each month’s theme brings a fresh batch of products to explore, which has been really fun. The products are high-quality, and I appreciate that they cater to different hair types. I’ve discovered a few new brands through the crate that I’ve already repurchased in full sizes. It’s a great way to experiment with different products without having to commit to the full size right away."
            image={reviewThree}
          />
        </div>
      </div>
    </div>
    
  );
}

export default Reviews;
