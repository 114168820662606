import React, { useState,useEffect } from "react";
import "./MonthLayer.css";
import Switch from '../SharedComponents/Switch';
import AnimatedButton from '../SharedComponents/AnimatedButton';
import highlightImage from '../../assets/Items/October/HairOil.png'
import showcaseImageOne from '../../assets/Items/October/HairClip.png'
import showcaseImageTwo from '../../assets/Items/October/OctoberHairShampoo.png'
import showcaseImageThree from '../../assets/Items/October/octoberScalpBrush.png'
import showcaseImageFour from '../../assets/Items/October/orangeScunchieTransparent.png'
import showcaseImageFive from '../../assets/Items/October/octoberHairSpray.png'
import showcaseImageGiveaway from '../../assets/Items/October/octoberGiveaway.png'
import showcaseImageTutorial from '../../assets/leaflet.png'


import Modal from 'react-modal';
import ShowcaseItem from './ShowcaseItem'; 

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:'#0e1b4d',
    color:'white',
    height:'40%',
    width:'40%'
    
  },
};

function MonthLayer() { 

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [prefillEmail,setPrefillEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const tokenSession = sessionStorage.getItem('token');
    const emailSession = sessionStorage.getItem('email');
    console.log(tokenSession);
    console.log(emailSession);
    if (tokenSession === null) {
      // User is not logged in
      setIsLoggedIn(false);
    }
    else{
      // Get Account Information
      // GetAccountInformation(emailSession,tokenSession);

      setIsLoggedIn(true);
      console.log(emailSession);
      if (emailSession !== null) {
          setPrefillEmail(`?prefilled_email=${emailSession}`);
      }
      console.log(prefillEmail);
    
    }


  }, []);

  const handleOneOffButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/28o4jVaSIfpRgrSeUW';
  };
  const handleSubscribeButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/5kA17Je4UdhJ8Zq3cd';
  };
  

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSwitchChange = (newValue) => {
      setIsSwitchOn(newValue);
      // Additional logic can be added here if needed
      console.log(newValue);
  };

  return (
    <div className="month-page">
      <div className="monthTheme">
        <div className="container">
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <h2 className="monthModalTitle">Purchase Options</h2>
        <button onClick={closeModal} className="monthModalCloseBtn">CLOSE</button>
        <div className="container ">
          <div className="row monthModalContainer">
            <div className="col col-lg-5 monthModalOneOff">
              <h4 className="monthModalContentTitle">One-Off Purchase</h4>
            </div>
            <div className="col col-lg-1"></div>
            <div className="col col-lg-1 "></div>
            <div className="col col-lg-5 monthModalSubscribe">
              <h4 className="monthModalContentTitle">Subscribe<span className="monthModalPromoText">*Most Popular*</span></h4>
            </div>
           
          </div>
        </div>
      </Modal>
      <div className="thisMonthsTheme">Next Months Theme</div>
        <div className="themeContainer"></div>
        <div className="showcaseContainer">
          <div className="showcaseTitle">Whats in the Box?</div>
          <div className="row showcaseItemContainer">
            {/* Product Leader */}
           
          <ShowcaseItem 
                productName="Lemon Hair Oil (20ml)" 
                company="Hair Syrup" 
                highlightImage={highlightImage}
                description="Citrus-infused treatment that adds shine and nourishment, perfect for keeping your hair vibrant and healthy for Halloween"
                width="col-lg-6"
            />
            <ShowcaseItem 
                productName="pH-balanced Shampoo (250ml)" 
                company="Grow Gorgeous" 
                highlightImage={showcaseImageGiveaway}
                description="Subscribe to enter the giveaway where one lucky subscriber will win a full sized premium product every month"
                width="col-lg-6"
                subscriberOnly="subscriberOnly"
            />

               <ShowcaseItem 
                productName="Skeleton Hair Clip" 
                company="CrownCrate" 
                highlightImage={showcaseImageOne}
                description="Spooky, stylish touch, perfect for embracing Halloween flair"
                width="col-lg-4"
            />
              <ShowcaseItem 
                productName="Giovanni 50/50 Balanced Shampoo (60ml)" 
                company="Giovanni" 
                highlightImage={showcaseImageTwo}
                description="Deeply cleanses and refreshes with a cool, tingling sensation, perfect for adding a spooky twist to your Halloween hair care"
                width="col-lg-4"
            />
            <ShowcaseItem 
                productName="Hair Scalp Brush" 
                company="CrownCrate" 
                highlightImage={showcaseImageThree}
                description="Gently massages your scalp, promoting circulation and distributing natural oils for healthy hair, perfect for Halloween hair care"
                width="col-lg-4"
            />
            <ShowcaseItem 
                productName="Halloween Scrunchie" 
                company="ROAR" 
                highlightImage={showcaseImageFour}
                description="Adds a festive touch to your hairstyle, perfect for celebrating the spooky season in style"
                width="col-lg-4 "
            />
              <ShowcaseItem 
                productName="Got2b Glued Blasting Freeze Spray (100ml)" 
                company="Schwarzkopf" 
                highlightImage={showcaseImageFive}
                description="Lock in your spooky Halloween style with a hairspray that holds all night!"
                width="col-lg-4"
            />
             <ShowcaseItem 
                productName="Tutorial Card" 
                company="CrownCrate" 
                highlightImage={showcaseImageTutorial}
                description="Curated by Melissa Antoinette, this card will provide a full product list and a link to a digital copy of her tutorials. Both in text and video!"
                width="col-lg-4"
            />

          </div>
          <div className="row p-3">
            <div  className=" col-lg-6"><div className="monthBtn" onClick={handleOneOffButtonClick}>ONE OFF PURCHASE<br/>£29.99</div>
            </div>
          <div className="col-lg-6">
            <div className="monthBtn" onClick={handleSubscribeButtonClick}>SUBSCRIBE<br/>£26.99</div>
          </div>
          
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default MonthLayer;
