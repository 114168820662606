import logo from './logo.svg';
import './App.css';

import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { useLocation } from 'react-router-dom';
import Main from './components/Main';
import Navbar from './Navbar';
import { SessionProvider } from './SessionContext';

import ReactGA from 'react-ga';
ReactGA.initialize('G-X7B61FD8ZT');

function App() {
  const [inputText, setInputText] = useState("");

  // const location = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  const onChangeHandler = event => {
    setInputText(event.target.value);
 };
 const sendInput = async () => {

};

  return (
    <SessionProvider>
    <div className="App">
      {/* <Navbar></Navbar> */}
       <Main />
    </div>
    </SessionProvider>
  );
}

export default App;
