import React from 'react';
import './Account.css';

function ReferralHistory() {
  return (
    <div className="container">
      Referral History
    </div>
  );
}

export default ReferralHistory;