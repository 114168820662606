import React, { useState, useRef, useEffect,useContext } from "react";
import "./Homepage.css";
import Modal from "./Modal";
import $ from "jquery";
import FAQComponent from "./FAQComponent/FAQComponent";
import PointsComponent from "./PointsComponent/PointsLayer";
import Socials from "./SocialsComponent/SocialsLayer";
import About from "./AboutComponent/About";
import MonthLayer from "./MonthComponent/MonthLayer";
import Footer from "./FooterComponent/Footer";
import Header from "./HeadingComponent/HeadingLayer";
import CarouselMoving from "./Carousel";
import sample from '../assets/WebsiteVideoNoLogo.mp4';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import logo from '../assets/logoText.png'
import { faCaretSquareDown } from '@fortawesome/fontawesome-free-regular'
import { SessionProvider } from '../SessionContext';
import PointsLayerNew from "./PointsComponent/PointsLayerNew";
import Reviews from "./ReviewComponent/Reviews";


function Homepage() {
  const month = [
    "January's",
    "February's",
    "March's",
    "April's",
    "May's",
    "June's",
    "July's",
    "August's",
    "September's",
    "October's",
    "November's",
    "December's",
  ];
  const d = new Date();
  const [currentMonth, setCurrentMonth] = useState(month[d.getMonth()].toUpperCase());
  const [offset, setOffset] = useState(0);
  const [disableFade, setDisableFade] = useState(false);
  const targetRef = useRef(null);

  // const { sessionData, updateSessionData } = useContext(SessionProvider);


  const scrollToPointsTarget = () => {
    targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

 
  useEffect(() => {
    var url = new URL(window.location.href);

    // updateSessionData("");

    // Check Cookie and set if null and param has code
    var codeCheck = getCookie("code");
    if (codeCheck == null) {
      var c = url.searchParams.get("code");
      if (c != null) {
        if (c.toLowerCase() == "trial5") {
          document.cookie = "code=trial5";
        }
      }
    }

    // Set code css styling
    if (codeCheck == "trial5") {
      $(".defaultPromo").css("display", "none");
      $(".trialPromo").css("display", "block");
    }

    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    else return null;
  }
  // console.log(window.scrollY);
  if (window.scrollY >= 1100) {
    // Update Stylings
    if (!disableFade) {
      // $('.previewBtn').fadeOut(400,function(){
      //     $(this).html("DECEMBER'S BOX").fadeIn(400);
      // })
      setDisableFade(true);
    }
  } else if (window.scrollY == 0) {
    if (disableFade) {
      // $('.previewBtn').fadeOut(400,function(){
      // $('.previewBtn').html('<span class="transitionDown">&#x21E9;</span> WHATS INSIDE DECEMBERS BOX? <span class="transitionDown">&#x21E9;</span>').fadeIn(400);
      // })
      setDisableFade(false);
    }
  }

  return (
    
    <div>    
      {/* <div className='logoTop'>
        <div className='logo'></div>
    </div> */}
      {/* <Header></Header> */}
      <div className="homeWrapper">
      <div className="videoTest">
        <video className='videoTag' autoPlay loop muted>
        < source src={sample} type='video/mp4' />
       </video>

       {/* <div className="fa"></div> */}
      </div>
      <div className="videoWrapper">
        <div className="logoWrapper">
          <div className="logoVideo row">
          <img className="col-lg-12" src={logo}></img>
          <div className="socials">
            <Socials></Socials>
          </div>
         </div>
        </div>
        <div className="moreInfoWrapper">
          <div className="moreInfoText">
            
            <button onClick={scrollToPointsTarget} className="exploreButton" style={{color:'#0e1b4d'}}><span>Keep Exploring</span><i></i></button>
          </div>
        </div>
      </div>
      </div>
    
      <div ref={targetRef} className="pointsLayer" id="pointsLayer">
        <PointsLayerNew></PointsLayerNew>
      </div>
      <div className="monthBoxLayer" id="monthLayer">
        <MonthLayer></MonthLayer>
      </div>
      <div className="reviewLayer" id="reviewLayer">
        <Reviews></Reviews>
      </div>
      {/* <div className="aboutLayer" id="aboutLayer">
        <About></About>
      </div>

      <div className="faqLayer" id="faqLayer">
        <FAQComponent></FAQComponent>
      </div> */}

      {/* Footer */}
      <Footer></Footer>
    </div>
  );
}

export default Homepage;
