import React from 'react';

const ShowcaseItem = ({ productName, company, highlightImage, description,width,subscriberOnly }) => {
    return (
        <div className={`showcaseHighlight ${width} ${subscriberOnly}`}>

            <div className="showcaseBorderPadding p-3">
            {subscriberOnly === 'subscriberOnly' && (
                        <div className="subscriberOnlyMessage">
                            <p>Subscriber Only Giveaway!</p>
                        </div>
                    )}
            <img className="showcaseImage" src={highlightImage} alt={productName} />
            <div className="showcaseProductInfoContainer">
                <div className="row">
                    <span className="showcaseItemTitleProduct col-lg-12">{productName}</span>
                </div>
                <div className="row">
                    <span className="showcaseItemTitleCompany col-lg-12">{company}</span>
                </div>
                <div className="row">
                   
                    <span className="col-lg-12 showcaseItemDescription">
                        {description}                    
                    </span>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ShowcaseItem;
