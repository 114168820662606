import React, { useState,useEffect,useContext } from 'react';
import './Account.css';
import AccountInformation from './AccountInformation';
import OrderHistory from './OrderHistory';
import ReferralHistory from './ReferralHistory';
import logo from '../../assets/logoText.png'
import { Form } from 'react-router-dom';
import sample from '../../assets/WebsiteVideoNoLogo.mp4';
import axios from 'axios';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SessionProvider} from '../../SessionContext';

function Account() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeComponent, setActiveComponent] = useState('AccountInformation');
  const [isLoginActive, setIsLoginActive] = useState(true);

  const[accountInformation,setAccountInformation] = useState(null);

  // const { sessionData, updateSessionData } = useContext(SessionProvider);

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\S]{6,16}$/;

  const handleTitleClick = (componentName) => {
    setActiveComponent(componentName === activeComponent ? null : componentName);
  };
  useEffect(() => {
    // updateSessionData("account");
    const tokenSession = sessionStorage.getItem('token');
    const emailSession = sessionStorage.getItem('email');
    console.log(tokenSession);
    console.log(emailSession);
    if (tokenSession === null) {
      // User is not logged in
      setIsLoggedIn(false);
    }
    else{
      // Get Account Information
      // GetAccountInformation(emailSession,tokenSession);

      setIsLoggedIn(true);
    }


  }, []);

  // const GetAccountInformation = (email,token) =>{

  // };
  // const GetAccountInformation = (email,token) =>{

  // };
  const [formDataLogin, setFormDataLogin] = useState({
    email: '',
    password:'',
    aspUserId:''
  });
  const [formDataRegister, setFormDataRegister] = useState({
    email: '',
    password:'',
    confirmPassword:'',
    onMailingList:false,
    aspUserId:''
  });

  // Update state on input change
  const handleChangeRegister = (e) => {
    const { name, value,type,checked } = e.target; // Get input name and value
    console.log("Name: " + name + " Value: " +checked);
    setFormDataRegister((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value, // Update the corresponding field in state
    }));
  };
  const handleChangeLogin = (e) => {
    const { name, value } = e.target; // Get input name and value
    console.log("Name: " + name + " Value: " +value);
    setFormDataLogin((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the corresponding field in state
    }));
  };
  function handleLogOut(){
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    window.location.reload();
  }
  function handleLogin(e) {
    e.preventDefault();
    let isValidated = true;
    if (!emailRegex.test(formDataLogin.email)) {
     // Email is incorrect
     isValidated = false;
      postToast("Email Address is not valid");
    }
    // Password field
    if (formDataLogin.password === "") {
      isValidated = false;
      postToast("Please enter your password");
    }

    if (isValidated) {
       postDataLogin(formDataLogin);
    }
  }
  function handleRegister(e) {
    e.preventDefault();
    // Validation
    let isValidated = true;
    if (!emailRegex.test(formDataRegister.email)) {
     // Email is incorrect
     isValidated = false;
      postToast("Email Address is not valid");
    }
    // Password field
    if (!passwordRegex.test(formDataRegister.password)) {
      isValidated = false;
      postToast("Password must be between 6-16 characters, containing at least 1 number, 1 uppercase and 1 lowercase character");
    }
    if (formDataRegister.password !== formDataRegister.confirmPassword) {
      isValidated = false;
      postToast("Passwords do not match!");
    }
    console.log(formDataRegister);

    if (isValidated) {
       postDataRegister(formDataRegister);
    }
   
  }
  const postDataRegister = async (data) => {
    try {
      const response = await axios.post('https://localhost:7224/Account/Register', data);
      console.log('Server Response:', response.data);
      console.log(data);
      // Handle the response data as needed
      if (response.data.message === "success") {
        postDataLogin({
          email:data.email,
          password:data.password,
          aspUserId:''
        });
      }
      else{
        postToast("Error Logging into account. Please try again!")
      }

    } catch (error) {
      console.error('There was an error!', error);
      // Handle errors here
    
    }
  };
  const postDataLogin = async (data) => {
    try {
      console.log(data);
      const response = await axios.post('https://localhost:7224/Account/Login', data);
      console.log('Server Response:', response.data);
      // Handle the response data as needed
      if (response.data.message === "success") {
        // Set tokens
        sessionStorage.setItem('email',data.email);
        sessionStorage.setItem('token',response.data.token);
        sessionStorage.setItem('expiration',data.expiration);

        // All ok, refresh page
        window.location.reload();
      }

    } catch (error) {
      console.error('There was an error!', error);
      // Handle errors here
    
    }
  };

  function postToast(message){
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Zoom,
      });
  }
  const handleLoginOptions = (isLogin) => {
    if (isLogin) {
      setIsLoginActive(true);
    }
    else{
      setIsLoginActive(false);
    }
  }

  function navigateHomepage() {
    window.location.href = '/';
  }
  

  return (  
    <div className='account-page'>
      <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss pauseOnHover theme="colored" transition= {Zoom}></ToastContainer>
      <div className="videoTest">
        <video className='videoTag' autoPlay loop muted>
        < source src={sample} type='video/mp4' />
       </video>
     </div>
    <div className="container accountWrapper">
      
      {!isLoggedIn &&
      <div className='accountContainer'>
      <div className='accountLoginContext'>
         <div className='accountImg' onClick={navigateHomepage}><img src={logo}></img></div>
       <div className='accountActionBar'><div className={`col-lg-6 accountActionItem ${isLoginActive ? 'active' : ''}`}  onClick={() => handleLoginOptions(true)}>LOGIN</div><div className={`col-lg-6 accountActionItem ${!isLoginActive ? 'active' : ''}`} onClick={() => handleLoginOptions(false)}>REGISTER</div></div>
       <div className='accountOptions'>
        {isLoginActive && 
          <form onSubmit={handleLogin}>
            <h2 className='accountTitle'>Welcome Back, Please Log In</h2>
            <div className='accountLoginOption'>
              <div className='accountPadding'>
         <div>Email</div>        
         <input type="text" placeholder='Enter your email' name='email' className='accountInput' onChange={handleChangeLogin} value={formDataLogin.email}/>
         </div>
         <div className='accountPadding'>
         <div>Password</div>        
         <input type="password" placeholder='Enter your password' name='password' className='accountInput'  onChange={handleChangeLogin} value={formDataLogin.password}/>
         </div>
         
       <div className='accountSubmitBtn'><button type="submit">Submit</button></div>
       </div>
     </form> 
     }
      {!isLoginActive && 
          <form onSubmit={handleRegister}>
           <h2 className='accountTitle'>Welcome, Please Register</h2>
            <div className='accountLoginOption'>
              <div className='accountPadding'>
         <div>Email</div>        
         <input type="text" name='email' placeholder='Enter your email' className='accountInput' onChange={handleChangeRegister} value={formDataRegister.email}/>
         </div>
         <div className='accountPadding'>
         <div >Password</div>        
         <input type="password" name='password' placeholder='Enter your password' className='accountInput' onChange={handleChangeRegister} value={formDataRegister.password}/>
         </div>
           <div className='accountPadding'>
         <div>Confirm Password</div>        
         <input type="password"  name='confirmPassword' placeholder='Confirm your password' className='accountInput' onChange={handleChangeRegister} value={formDataRegister.confirmPassword}/>
         </div>
         <div className='accountPaddingCheck'>             
         <input type="checkbox" name='onMailingList' className='accountCheckbox form-check-input' onChange={handleChangeRegister} checked={formDataRegister.onMailingList}/>
         <span className=''>Join CrownCrate mailing list</span>   
         </div>
       <div className='accountSubmitBtn col-lg-2' ><button type="submit">Submit</button></div>
       </div>
     </form> 
     }
       </div>
     
      </div>
      </div>
      }
      
      {isLoggedIn && 
      <div className='accountInformationContainer'> 
        <div className='accountImg'><img src={logo}></img></div>
        <div className='row'>  
        <div className="accountSidePanel col-lg-3">
            <h2 className="section-title" onClick={() => handleTitleClick('AccountInformation')}>Account Information</h2>        
            <h2 className="section-title" onClick={() => handleTitleClick('OrderHistory')}>Order History</h2>        
            <h2 className="section-title" onClick={() => handleTitleClick('ReferralHistory')}>Referral History</h2>  
            <h2 className="section-title accountLogOut" onClick={() => handleLogOut()}>Log Out</h2>     
        </div>
        <div className='col-lg-1'></div>
        <div className='accountMainPanel col-lg-8'>
      {activeComponent === 'AccountInformation' && <AccountInformation />}
      {activeComponent === 'OrderHistory' && <OrderHistory />}
      {activeComponent === 'ReferralHistory' && <ReferralHistory />}
      </div>
      </div> 
      </div> 
      }
    </div>
    </div>
   
  );
}

export default Account;