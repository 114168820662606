import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState(null);

  // Set session data
  const setSession = (data) => {
    setSessionData(data);
  };

  // Clear session data
  const clearSession = () => {
    setSessionData(null);
  };

  return (
    <SessionContext.Provider value={{ sessionData, setSession, clearSession }}>
      {children}
    </SessionContext.Provider>
  );
};
