import Carousel from "react-bootstrap/Carousel";

function CarouselMoving() {
  return (
    <Carousel indicators={false} fade>
      <Carousel.Item>
        <div className="carouselImageOne row">
        <div className="col-lg-1"></div>
          <div className="captionBox col-lg-3">
            <div className="captionTitle textLarge">
              GET YOUR STAND-ALONE TRIAL BOX IN TIME FOR CHRISTMAS FOR{" "}
              <span className="defaultPromo">£26.99!</span>
              <span className="trialPromo">
                <span className="trailPriceNormal strikethrough">£26.99 </span> - £21.99
              </span>
            </div>
            <div className="captionText">
            Unlock the benefits of becoming a founder with us, and enjoy an exclusive 10% discount on your future subscriptions, plus so much more! Our upcoming subscription boxes will feature exciting and unique monthly themes, packed with a diverse selection of hair care products and handmade creations by "ROAR." To top it all off, each box will include a comprehensive "How To" guide, empowering you to effortlessly create a fresh, stylish look every month!
            </div>
            <div className="subscribeBox">
            <button className="subscribeBtn btnMedium">
              <a
                className="trialPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144?prefilled_promo_code=trial5"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
              <a
                className="defaultPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
            </button>
          </div>
          </div>
          <div className="col-lg-7"></div>
          <div className="col-lg-1"></div>
          
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carouselImageTwo row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 crouselTwoContent">
          <div className="carouselTwoText"><div className="textLarge">LOOKING FOR THAT PERFECT GIFT?</div><div>Transform your loved ones hair care routine with our curated subscription box, delivering salon-quality results to your doorstep</div><div className="carouselTwoQuote">because luxury should be as convenient as it is beautiful.</div></div>
          <div className="subscribeBox">          
            <button className="subscribeBtn btnMedium">
              <a
                className="trialPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144?prefilled_promo_code=trial5"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
              <a
                className="defaultPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
            </button>
          </div>
          </div>
          <div className="col-lg-3"></div>
      
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carouselImageThree row">         
          <div className="subscribeBox imageThreeContent">
            <button className="subscribeBtn btnMedium">
              <a
                className="trialPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144?prefilled_promo_code=trial5"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
              <a
                className="defaultPromo"
                href="https://buy.stripe.com/7sIg2D3qg5Ph2B2144"
                target="_blank"
              >
                CLICK HERE TO PURCHASE
              </a>
            </button>
          </div>
        </div>
      </Carousel.Item>     
    </Carousel>
  );
}

export default CarouselMoving;
