import React, { useState } from 'react';

const Privacy = () => {
    return (
        <div className="privacy-container">
            Privacy Policy

1. Introduction

CrownCrate ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website or use our services. By accessing or using our website and services, you agree to the terms of this Privacy Policy.

2. Information We Collect

2.1 Personal Information: We may collect personal information that you provide directly to us when you create an account, subscribe to our services, make a purchase, or contact us. This information may include, but is not limited to:

Name
Email address
Billing address
Shipping address
Phone number
Payment information
2.2 Automatically Collected Information: When you visit our website, we may automatically collect certain information about your device and usage, including:

IP address
Browser type and version
Operating system
Referring website
Pages viewed and links clicked
Time and date of access
2.3 Cookies and Similar Technologies: We use cookies and similar tracking technologies to collect information about your interactions with our website. You can control the use of cookies at the individual browser level. If you choose to disable cookies, some features of our website may not function properly.

3. How We Use Your Information

We use the information we collect for various purposes, including:

To provide and manage our services
To process your orders and payments
To communicate with you about your account, orders, and services
To improve our website and services
To personalize your experience
To send you promotional materials and other communications (you may opt out at any time)
To comply with legal obligations and protect our rights and interests
4. Information Sharing and Disclosure

We do not sell, trade, or rent your personal information to third parties. However, we may share your information with third parties in the following circumstances:

Service Providers: We may share your information with trusted service providers who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that business transaction.
5. Data Security

We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.

6. Your Rights and Choices

6.1 Access and Update: You have the right to access and update your personal information at any time by logging into your account or contacting us directly.

6.2 Opt-Out: You may opt out of receiving promotional communications from us by following the unsubscribe instructions included in those communications or by contacting us.

6.3 Cookies: You can control the use of cookies through your browser settings. Please note that if you delete or choose not to accept cookies, you may not be able to use some features of our website.

7. Third-Party Websites

Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites. We encourage you to read the privacy policies of any third-party sites you visit.

8. Children's Privacy

Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.

9. Changes to This Privacy Policy

CrownCrate reserves the right to update or change this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. It is your responsibility to review this Privacy Policy periodically for any changes. Your continued use of the website and services after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified policy.

10. Contact Us

If you have any questions about this Privacy Policy, please contact us at contact@crowncrate.co.uk

Effective Date: 02/09/2024

By using our website and services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
        </div>
    );
};

export default Privacy;
