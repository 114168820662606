import React, { useState,useRef,useEffect } from "react";
import "./PointsLayerNew.css";
import pointOne from '../../assets/HairRibbonPromo.mp4';
import pointTwo from '../../assets/GemsPromo.mp4';
import pointThree from '../../assets/TinselPromo.mp4';
import FadeInSection from "../SharedComponents/FadeInSection";
import textStyle from '../../assets/StyleText.png';
import textBudget from '../../assets/BudgetText.png';
import textCommunity from '../../assets/CommunityText.png';
import imgBudget from '../../assets/BudgetImage.png';
import imgStyle from '../../assets/styleImageTwo.png';

function PointsLayerNew() {
  const [isHovered, setIsHovered] = useState(false);

  const handleOneOffButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/28o4jVaSIfpRgrSeUW';
  };
  const handleSubscribeButtonClick = () => {
    window.location.href = 'https://buy.stripe.com/5kA17Je4UdhJ8Zq3cd';
  };
  

  return (
    
    <div className="points-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pointsTitleNew">CRATE YOUR...</div>
         <div className="col-lg-3 pointsContext">
            <div>
              <img className="imgStyle" src={imgStyle}></img>
            </div>
            <div className="styleTitle">
              <span>STYLE</span>
            </div>
            <div>
              <span>Premium Salon Supplies & Expert Tutorials By An Award Winning Hair Stylist</span>
            </div>
          </div>
         <div className="col-lg-3">
          <div className="pointsNormalContainer">
          <div className="pointsBoxHeadingNormal">REASONS TO PICK US</div>
          <div className="pointsBoxNormal">SAMPLE OF LUXURY PRODUCTS</div>
          <div className="pointsBoxNormal">EASY TO FOLLOW TUTORIALS</div>
          <div className="pointsBoxNormal">NEW THEMES EVERY MONTH</div>
          <div className="pointsBtn" onClick={handleOneOffButtonClick}>ONE OFF PURCHASE<br/>£29.99</div>
          </div>
         </div>
         <div className="col-lg-3">
         <div className="pointsNormalContainer">
          <div className="pointsBoxHeadingSubscriber">SUBSCRIBER ONLY BENEFITS</div>
          <div className="pointsBoxNormal">10% OFF EVERY SINGLE MONTH</div>
          <div className="pointsBoxNormal">MONTHLY GIVEAWAYS</div>
          <div className="pointsBoxNormal">BE PART OF OUR HAIR COMMUNITY</div>
          <div className="pointsBtn" onClick={handleSubscribeButtonClick}>SUBSCRIBE<br/>£26.99</div>
          </div>
         </div>
         <div className="col-lg-3 pointsContext">
         <div>
              <img className="imgStyle" src={imgBudget}></img>
            </div>
            <div className="styleTitle">
              <span>BUDGET</span>
            </div>
            <div>
              <span>Affordable, Luxury Hair Crates for Just £26.99 with a Subscription – One-Off Purchase Also Available!</span>
            </div>
         </div>
        </div>
      </div>
    </div>
    
  );
}

export default PointsLayerNew;
