import React from 'react';

const ReviewItem = ({ name,message,image,videoLink,isEven }) => {
    return (
       <div className='col-lg-4 reviewItemMargin'>
        <div className='reviewItemContainer'>
            {isEven && (
                <div className='reviewItemContext'>
                 <div>
                    {!image && (
                         <video className='reviewItemVideo' autoPlay loop muted>
            <source src={videoLink} type='video/mp4' />
            </video>
                    )}
                    {image &&(
                        <img className='reviewItemVideo' src={image} />
                    )}         
            </div>
            <div className='reviewItemName'>{name}</div>
            <div className='reviewItemMessage'>{message}</div>
             </div>
            )}    
                {!isEven && (
                <div className='reviewItemContext'>              
            <div className='reviewItemName'>{name}</div>
            <div className='reviewItemMessage'>{message}</div> 
            <div>
            <video className='reviewItemVideo' autoPlay loop muted>
            <source src={videoLink} type='video/mp4' />
            </video>
            </div>
             </div>
            )}         
                      
        </div>

       </div>
    );
};

export default ReviewItem;
