import React, { useState } from 'react';

const Terms = () => {
    return (
        <div className="terms-container">
            Terms and Conditions

1. Introduction

Welcome to CrownCrate. These Terms and Conditions ("Terms") govern your use of our services, including subscriptions and one-off purchases, provided by CrownCrate. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our services.

2. Subscription Services

2.1 Subscription Plans: By subscribing to our services, you agree to pay the subscription fees as outlined on our website. Subscription fees will be billed on a recurring basis in accordance with the billing cycle of your chosen subscription plan.

2.2 Cut-off Date: The cut-off date for subscription changes, cancellations, and refunds is the 27th of each month. Any subscription modifications must be made before this date.

2.3 Billing and Shipping: Payments for subscriptions will be processed immediately upon enrollment and subsequently on the same day each month. Items will be shipped during the first week of each month.

2.4 Cancellations: If you wish to cancel your subscription, please do so before the 27th of the month. Cancellations made before this date will prevent future payments from being taken. Cancellations made after the 27th will not be eligible for refunds for that month.

2.5 Refunds: Refunds for subscriptions are only available if the cancellation is requested before the 27th of the month. After this date, refunds will not be issued.

3. One-Off Purchases

3.1 Payment: Payment for one-off purchases will be taken immediately upon placing the order.

3.2 Refund Policy: If you wish to cancel a one-off purchase, you must request a refund before the 27th of the month. Cancellations and refund requests made after this date will not be eligible for refunds.

4. General Conditions

4.1 Changes to Services and Prices: CrownCrate reserves the right to modify or discontinue the services (or any part thereof) at any time, with or without notice. Prices for all products are subject to change without notice.

4.2 Limitation of Liability: CrownCrate will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use of, or the inability to use, our services.

4.3 Indemnification: You agree to indemnify, defend, and hold harmless CrownCrate, its directors, officers, employees, affiliates, agents, contractors, and licensors from any claim or demand, including reasonable attorneys’ fees, arising out of your breach of these Terms or your violation of any law or the rights of a third party.

4.4 Governing Law: These Terms shall be governed by and construed in accordance with the laws, without regard to its conflict of law provisions.

5. Changes to Terms and Conditions

CrownCrate reserves the right to update or change these Terms at any time. Any changes will be posted on this page with an updated effective date. It is your responsibility to check this page periodically for changes. Your continued use of the service after any modifications to the Terms will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Terms.

6. Contact Information

If you have any questions about these Terms, please contact us at [Your Contact Information].

Effective Date: 02/08/2024

By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </div>
    );
};

export default Terms;
