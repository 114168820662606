import React,{ useState,useEffect }  from 'react';
import './Account.css';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import axios from 'axios';

function AccountInformation() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const tokenSession = sessionStorage.getItem('token');
    const emailSession = sessionStorage.getItem('email');

    // Get all data
    getAccountInformation(tokenSession,emailSession);

  }, []);

  const getAccountInformation = async (token,email) => {
    console.log(token);
    console.log(email);
    const params = {
      email: email,
      token: token
    };
    try {
      const response = await axios.get('https://localhost:7224/Account/GetUserInformationByEmail', {params});
      console.log('Server Response:', response.data);
      // Handle the response data as needed
      if (response.data.message === "success") {
        setEmail(response.data.user.email);
      }
      else{
        postToast(response.data.content);
      }

    } catch (error) {
      console.error('There was an error!', error);
      // Handle errors here
    
    }
  };
  function postToast(message){
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Zoom,
      });
  }


  return (
    <div className="container">
      <div className='mainPanelTitle'><h2>Account Information</h2></div>
      <div>
        <div><span>Email Address: </span>{email}</div>
      </div>
    </div>
  );
}

export default AccountInformation;