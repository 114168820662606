import React from 'react';
import './Account.css';

function OrderHistory() {
  return (
    <div className="container">
      Order History
    </div>
  );
}

export default OrderHistory;