import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import "./SocialsLayer.css";

function Socials() {
  return (
    <div className="socials-page container">
      <div className="row socialsBar textMedium">
        <div className="col-lg-2"></div>
        <div className="col-lg-2">FOLLOW US!</div>
        <div className="col-lg-2 socialLink"> 
          <a href="https://www.instagram.com/crowncrate/" target="_blank"><FontAwesomeIcon icon={faInstagram} /><span>INSTAGRAM</span></a>
        </div>
        <div className="col-lg-2 socialLink">
          <a href="https://www.tiktok.com/@crowncrate?_t=8hoylcXUvXV&_r=1" target="_blank"><FontAwesomeIcon icon={faTiktok} /><span>TIKTOK</span></a>
        </div>
        <div className="col-lg-2 socialLink"> 
          <a href="https://www.youtube.com/channel/UCAdhOKgbBNeKeG6K91GZ4GA" target="_blank">{" "}<FontAwesomeIcon icon={faYoutube} /><span className="">YOUTUBE</span></a>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
}

export default Socials;
