import React from 'react';
import { BrowserRouter,Routes, Route } from 'react-router-dom';

import Homepage from '../components/Homepage';
import Account from './AccountsComponent/Account';
import Terms from '../components/Terms';
import Privacy from '../components/Privacy';

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path='/' element={<Homepage />}></Route>
      <Route path='/account' element={<Account />}></Route>
      <Route path='/terms' element={<Terms />}></Route>
      <Route path='/privacy' element={<Privacy />}></Route>
    </Routes>
  );
}

export default Main;