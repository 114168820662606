import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-page">
      <div className="row icons">
        <ul>
          <li>
            <a href="https://www.instagram.com/crowncrate/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCAdhOKgbBNeKeG6K91GZ4GA" target="_blank">
              {" "}
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@crowncrate?_t=8hoylcXUvXV&_r=1" target="_blank">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </li>
        </ul>
      </div>

      <div className="row">
        <ul>
          <li>
            <a href="mailto:contact@crowncrate.co.uk">Contact us</a>
          </li>
          <li>
            <a href="#">Our Services</a>
          </li>
          <li>         
            <Link to="/Privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/Terms">Terms & Conditions</Link>
          </li>
          <li>
            <a href="#">Career</a>
          </li>
        </ul>
      </div>
      <div className="row">
        <span>CrownCrate Copyright © 2024 - All Rights Reserved</span>
      </div>
    </div>
  );
}

export default Footer;
